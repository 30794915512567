<template>
    <div
        class="alert_block"
        @click="showPage"
    >
        <div class="alert_block_header">
            <div class="alert_block_header_icon">
                <avatar
                    v-if="hasUser"
                    :avatar-url="getUser.avatar_url"
                    :avatar-fallback="getUser.avatar_fallback"
                    :firstname="getUser.firstname"
                    :lastname="getUser.lastname"
                    :nickname="getUser.nickname"
                    :user="'writer'"
                />
                <component
                    :is="data.type"
                    v-else
                />
                <i
                    v-if="isUnread"
                    class="circle_unread"
                />
            </div>
            <div class="alert_block_header_info">
                <p class="alert_block_title">
                    {{ parsedData.data.subject }}
                </p>
                <p class="alert_block_date">
                    <span>{{ data.created_at | moment_from }} ago</span>
                    <span v-if="parsedData && parsedData.data.orderid">Order ID: {{ parsedData.data.orderid }}</span>
                </p>
            </div>
        </div>
        <div class="alert_block_body">
            {{ parsedData.data.message }}
        </div>
    </div>
</template>

<script>
import NewMessage from '@/components/icons/alert/NewMessage.vue';
import NewFileAdditional from '@/components/icons/alert/NewFileAdditional.vue';
import NewFileMain from '@/components/icons/alert/NewFileMain.vue';
import NewTicketReply from '@/components/icons/alert/NewTicketReply.vue';
import ReferralSignup from '@/components/icons/alert/ReferralSignup.vue';
import Credit from '@/components/icons/alert/Credit.vue';
import Avatar from '@/components/common/Avatar.vue'
import filtersMixin from '@/mixins/filters-mixin'

export default {
    name: 'AlertItem',
    components: {
        NewMessage,
        NewFileAdditional,
        NewFileMain,
        NewTicketReply,
        ReferralSignup,
        Credit,
        Avatar
    },
    mixins: [
        filtersMixin
    ],
    props: {
        data: {
            type: Object,
            required: true
        },
        isUnread: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        parsedData() {
            return this.data.data
        },
        hasUser() {
            return !!this.parsedData.user
        },
        getUser() {
            return this.parsedData.user
        }
    },
    created() {
        console.log(this.data)
    },
    methods: {
        showPage() {
            window.location.href = this.parsedData.data.url
        }
    }
}
</script>
